import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import IndustryPageAnnouncements from "../components/IndustryPageAnnouncements"
import Testimonial from "../components/Testimonial"
import { GatsbyImage } from "gatsby-plugin-image"

import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const Retail = ({ data, location }) => {
  const content = data.contentfulIndustryPage
  const announcements = data.allContentfulAnnouncement.nodes
  const testimonials = data.allContentfulTestimonial.nodes

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (content, children) => <p>{children}</p>,
    },
  }

  return (
    <Layout location={location}>
      <Seo title="Retail" />
      <div className="section-wrapper page-main">
        <div className="content-column">
          <h1 className="page-main-title">Retail</h1>

          <GatsbyImage image={content.image.gatsbyImageData} alt="A forklift moving a pallet of products" className="industry-image" />

          <div className="text-section">
            <h2 id="capabilities">About</h2>
            <div>{renderRichText(content.capabilities, options)}</div>
          </div>
          {/* <div className="text-section">
            <h2 id="accomplishments">Accomplishments</h2>
            <div>{renderRichText(content.accomplishments, options)}</div>
          </div> */}
          {(testimonials.length > 0) &&
            <div className="text-section">
              <h2 id="testimonials">Testimonials</h2>
              {testimonials.map( testimonial => {
                return(
                  <Testimonial
                    name={testimonial.name}
                    // title={testimonial.titleAndCompany}
                    quote={testimonial.quote.quote}
                    // image={testimonial.headshot.fixed.src}
                  />
                )
              })}
            </div>
          }
          {(announcements.length > 0) &&
            <div className="text-section">
              <h2 id="announcements">Relevant Announcements</h2>
              <IndustryPageAnnouncements announcements={announcements}/>
            </div>
          }
          
        </div>
        <div className="index-column">
          <AnchorLink to="/retail#capabilities" className="index-item">About</AnchorLink>
          {/* <AnchorLink to="/retail#accomplishments" className="index-item">Accomplishments</AnchorLink> */}
          {(testimonials.length > 0) &&
            <AnchorLink to="/retail#testimonials" className="index-item">Testimonials</AnchorLink>
          }
          {(announcements.length > 0) &&
            <AnchorLink to="/retail#announcements" className="index-item">Relevant Announcements</AnchorLink>
          }
        </div>
      </div>
    </Layout>
  )
}

export default Retail

export const pageQuery = graphql`
  query RetailContent {
    site {
      siteMetadata {
        title
      }
    }
    contentfulIndustryPage( slug: { eq: "retail" } ) {
      slug
      title
      image {
        gatsbyImageData
      }
      capabilities {
        raw
      }
    }
    allContentfulAnnouncement(
      filter: {taggedIndustry: {eq: "Retail"} }
      sort: { fields: [date], order: DESC }
      ) {
      nodes {
        slug
        title
        date(formatString: "MMMM Do, YYYY")
      }
    }
    allContentfulTestimonial(
      filter: {taggedIndustry: {eq: "Retail"} }
      ) {
      nodes {
        name
        quote {
          quote
        }
      }
    }
  }
`